.advertisement {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.advertisement button {
    margin-top: 1rem;
    width: 100%;
}
