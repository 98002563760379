/** Screen ANTECEDANTS */

.areaAntecedants {
    width: inherit;
    padding: 10px;
    height: 10em;
    font-family: Roboto;
    font-size: 16px;
    border: 1px solid #DEDEDE;
    border-radius: 5px;
}

/** Screen MEDICAMENTS */
.medicamentsList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 16px;
}

.medForm {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

}

.medTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.medTitleP {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 700;
}

.addMedBtn {
    margin-top: 16px;
    margin-bottom: 16px;
    align-self: center;
    align-items: center;
    background-color: #239497;
    color: #FFFFFF;
}

.discretButton {
    cursor: pointer;
    border: 0px;
    background-color: #FFFFFF;
    font-family: Roboto;
    font-size: 14px;
    margin-left: 6px;
    margin-top: 6px;
}

.inputFormUserdata last {
    margin-bottom: 0px;
}

.infoPanel {
    padding: 16px;
}

.infoPanel p {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
}

.infoTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.infoTitle p {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
}

