.premiumApp {
  background-color: #FFFFFF !important;
  font-family: Inter;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.premiumHeader {
  display: flex;
  justify-content: space-between;
}

.premiumApp h1 {
  color: #3A4248;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}

.premiumVisual {
  display: block !important;
  text-align: center;
}

.premiumVisualXL {
  display: none !important;
}

.premiumScreenBox {
  align-items: center;
  cursor: pointer;
  border-radius: 24px;
  border: 2px solid #F46B45;
  display: flex;
  flex-direction: column;
  height: 6em;
  margin-top: 24px;
  padding: 22px 16px;
  position: relative;
}

.premiumScreenBox.boxSmall.premiumScreenBoxTrial {
  height: 7em;
}

.premiumScreenBox p {
  color: #666;
  margin: 0;
}

.selectedBox {
  cursor: pointer;
  background-color: rgba(255, 203, 188, 0.1);
  border-radius: 24px;
  box-shadow: 0px 0px 0 2px #F56E46;
}

.selectedBox p {
  color: #F56E46 !important;
}

.premiumFeature {
  margin: 2rem auto;
}

.premiumFeature>div {
  display: flex;
  margin-bottom: 1rem;
}

.premiumFeature p {
  color: #3a4248;
  margin-left: 1rem;
  margin-block-end: 0;
  margin-block-start: 0;
}

.premiumApp .loginMessage {
  color: #666666;
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 0.9rem;
  text-align: center;
}

.premiumTitle {
  color: #071B2E !important;
  font-family: Inter;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.premiumScreenBox.premiumScreenBoxAnnual.premiumScreenBoxTrial .premiumTitle {
  margin-bottom: 0 !important;
}

.premiumScreenBox.premiumScreenBoxMonth .premiumTitle, 
.premiumScreenBox.premiumScreenBoxAnnual .premiumTitle {
  margin-bottom: 1rem !important;
}

.premiumScreenBox.premiumScreenBoxMonth.boxSmall .premiumTitle,
.premiumScreenBox.premiumScreenBoxAnnual.boxSmall .premiumTitle {
  margin-bottom: 0 !important;
}

.premiumScreenBoxMonth.boxSmall.premiumScreenBoxTrial .premiumTitle {
  margin-bottom: 1.5rem !important;
}

.premiumScreenBox.premiumScreenBoxMonth.premiumScreenBoxTrial .premiumTitle {
  margin-bottom: 1.5rem !important;
}

.premiumScreenBox .price {
  color: #666666;
  font-family: Inter;
}

.premiumScreenBox>.premiumHelperText {
  color: white !important;
  background: linear-gradient(90deg, #F46B45 0%, #EEA849 100%);
  border-radius: 4px;
  font-size: 12px;
  position: absolute;
  padding: 0.5rem 0.75rem;
  right: 20px;
  top: -22px;
}

.premiumOffers {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.premiumApp .acceptCGV {
  display: flex;
  margin-top: 0 !important;
}

.premiumApp .acceptCGV p {
  margin-block-end: 0;
  margin-block-start: 1.5rem;
}

.premiumApp .acceptCGV>span {
  margin-left: -0.65rem;
}

.premiumApp .acceptCGV a {
  color: #000;
  font-weight: 600;
}

.premiumInfo .subscribeButton button {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.premiumTopRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.successPremiumTitle {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  margin-top: 2rem;
  text-align: center;
}

.successPremiumLabel {
  font-family: Poppins;
  font-size: 12px;
  text-align: center;
}

.premium-popup-content {
  padding: 16px;
  min-width: 20rem;
}

.successPremiumBtn {
  display: flex;
  justify-content: center;
}

.successPremiumBtn button {
  font-size: 0.65rem;
}

@media screen and (min-width: 576px) {
  .premiumApp h1 {
    font-size: 28px;
  }

  .premiumInfo {
    max-width: 55rem;
    margin: 1rem auto;
  }

  .premiumOffers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .premiumScreenBox {
    max-width: 20rem;
    width: 20rem;
  }

  .premiumFeature {
    margin-bottom: 2.5rem;
    margin-top: 3rem;
  }

  .premiumFeature>div {
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .premiumVisual {
    display: none !important;
  }

  .premiumVisualXL {
    display: block !important;
    margin-top: 2rem;
    text-align: center;
  }

  .premiumApp .loginMessage {
    font-size: 1rem;
  }

  .premiumScreenBox .premiumTitle {
    font-size: 28px;
  }

  .premiumScreenBox .price {
    font-size: 20px;
  }

  .premiumApp .acceptCGV p {
    font-size: 1rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  .premiumInfo .subscribeButton button {
    margin-top: 2rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .successPremiumTitle {
    font-size: 24px;
  }

  .successPremiumLabel {
    font-size: 16px;
  }

  .successPremiumBtn button {
    font-size: 1rem;
  }
}
