@media screen and (max-height: 919px)  {
    .bottomBtnAbo {
        margin-top: 200px;
        position: relative;
    }
    .bottomBtnAbo Button {
        width: 100% !important;
    }
}

@media screen and (max-width: 374px) {
    .bottomBtnAbo {
        margin-top: 200px;
        position: relative;
    }
    .bottomBtnAbo Button {
        width: 100% !important;
    }
}

@media screen and (min-height: 920px) and (min-width: 375px) {
  .bottomBtnAbo {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
    .mainHome {
        padding-top: 8px;
        background-color: #FFFFFF;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 1em;
    }

    .accueilDisplay {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .accueilDisplayLogo {
        display: flex;
        justify-content: center;
        column-gap: 0.25em;
        align-items: center;
        padding: 1em;
    }
    .accueilDisplayCol {
        width: 45%;
    }

    .homeMainTitleDisplay {
        justify-content: center;
    }
    .homeMainTitleDisplay p {
        align-items: center;
        text-align: center;
    }
    .headerHomeMenu {
        color: white !important;
        text-transform: unset !important;
        font-family: Inter;
        font-size: 16px;
        font-weight: 600;
    }
    .premiumBox {
        margin-left: 25%;
        margin-right: 25%;
    }
    .homeMainVisual {
        display: none;
    }
    .unsubPopup-content {
        padding: 2em 3em 2em 3em !important;
        width: 50% !important;
    }
    .reviveSubscriptionPopup-content {
        padding: 2em 3em 2em 3em !important;
        width: 50% !important;
    }
}

@media screen and (max-width: 1023px) {
    .mainHome {
        padding-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        background-color: #FFFFFF;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        margin-bottom: 60px;
    }

    .headerHomeMenu {
        display: none !important;
    }
    .accueilDisplayLogo {
        display: none !important;
    }
    .homeMainVisual {
        display: none;
    }
}

@media screen and (max-width: 425px) {
    .homeMainVisualXL {
        display: none !important;
    }
    .homeMainVisual {
        display: block !important;;
    }
}

.accueilDisplayLegal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 8px;
    font-size: 12px;
    
}

.accueilDisplayLegal div {
    text-transform: unset !important;
}

.bottomBtnAbo {
    display: flex;
    flex-direction: column;
}

.headerHome {
    display: flex;
    flex-direction:row;
    margin-left: 25px;
    margin-right: 18px;
    height: 64px;
    align-items: center;
    justify-content: space-between;
}

.homeTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #071B2E;
    align-self: center;
}

.homeSecondTitle {
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #071B2E;
    margin-bottom: 0px;
}


.homeSubtitle {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0em;
    text-align: center;
    color: #071B2E;
}

.accueilColTitle {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #071B2E;
}

.homeRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}


.homeRow p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

.homeRowSub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.homeText {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #3A4248;
    word-wrap: break-word;
}

.homeLink {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0.5em;
    padding: 0.75em 0.5em 0.75em 1em;
    margin-top: 1em;
}

.homeLink p {
    color: #FFFFFF;
}

.homeIcon {
    align-self: center;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 6px;
    padding-bottom: 3px;
    margin-right: 12px;
    background-color: #E9F5F5;
    border-radius: 0.3em;
}

.homeIconFiles {
    align-self: center;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 6px;
    padding-bottom: 3px;
    margin-right: 12px;
    background-color: #E9F5F5;
    border-radius: 0.3em;
}


.homeIconOrange {
    align-self: center;
    padding-left: 7px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 3px;
    margin-right: 12px;
    background-color: #F56E4630;
    border-radius: 0.3em;
}

.homeIconGrey {
    align-self: center;
    padding-left: 8px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 3px;
    margin-right: 12px;
    background-color: #726b6930;
    border-radius: 0.3em;
}

.homePro {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1em;
    margin-top: 16px;
    border-radius: 1em;
    border: 2px solid;
    border-color: #E04643;
    background-color: #E0464330;
    padding: 0.5em 1em 0.5em 1.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
}

.homePro h6 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 16px;
}

.homePro p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.homeHistory {
    padding: 0em 1em;
}

.subscriptionInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ACCUEIL PAGE **/
.homeMainTitle {
    padding-top: 1em;
    text-align: center;

}

.homeMainTitle p {
    font-family: Poppins;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 0em;
}

.accueilBox {
    padding: 12px;
    border-radius: 0.3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    background-color: #FFFFFF;
}

.accueilBox p {
    margin-top: 0;
    margin-bottom: 0;
    font-family: Inter;
    color: #071B2E;
    letter-spacing: 0.3px;
}

.accueilBoxDate {
    padding-bottom: 6px;
    color: #3A4248;
    font-size: 10px;
    font-weight: 400;
}

.accueilBoxType {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 4px;
}

.accueilBoxProd {
    font-size: 12px;
    font-weight: 400;
}

.accueilLien {
    padding: 6px;
    font-size: 12px;
    border-radius: 0.5em;
}

.accueilMainBox {
    margin-top: 1em;
    padding: 0.5em 0.5em;
    border: 1px solid transparent;
    border-radius: 1rem;
    border-width: 10px;
    position: relative;
    margin-bottom: 1.25em;
}

.accueilMainBox::before {
    content: "";
    position: absolute;
    top: -7px;
    bottom: -7px;
    left: -7px;
    right: -7px;
    border: 8px solid #fff;
    border-radius: .8rem;
}

.accueilMainBox p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.accueilMainBox.blueBox {
    box-sizing: border-box;
    background: linear-gradient(#fff, #fff), 
                linear-gradient(111.87deg, #00CDAC, #02AAB0);
    background-repeat: no-repeat;
    background-origin: padding-box, border-box;
}

.accueilMainBox.orangeBox {
    box-sizing: border-box;
    background: linear-gradient(#fff, #fff), 
                linear-gradient(111.87deg, #F46B45, #EEA849);
    background-repeat: no-repeat;
    background-origin: padding-box, border-box;
}

.accueilMainBox.greyBox {
    box-sizing: border-box;
    background: linear-gradient(#fff, #fff), 
                #8A9299;
    background-repeat: no-repeat;
    background-origin: padding-box, border-box;
}

.blueBox .homeRow {
    margin-bottom: 1em;
}

.homeRowSub {
    margin-bottom: 1em;
}


/* SEARCH PAGE **/
.searchBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.searchBoxRow p {
    font-family: Inter;
    margin-top: 0px;
    margin-bottom: 0px;
}

.searchBoxVoie {
    font-weight: 400;
    font-size: 12px;
    color: #AFAFAF;
}

.searchBoxProd {
    font-size: 14px;
    font-weight: 600;
}

.searchIcon {
    max-height: 64px;
    max-width: 64px;
    padding: 20px;
    border-radius: 0.5em;
    justify-content: center;
    margin-right: 16px;
}

.premiumBox {
    box-sizing: border-box;
    margin-top: 2em;
    padding: 2em 2em;
    background: linear-gradient(#fff, #fff), 
                linear-gradient(111.87deg, #F46B45, #EEA849);
    border: 5px solid transparent;
    background-repeat: no-repeat;
    background-origin: padding-box, border-box;
    border-radius: 1rem;
    border-width: 10px;
    position: relative;
    text-align: center;
}

.premiumBox::before {
    content: "";
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    border: 6px solid #fff;
    border-radius: .8rem;
}

.premiumBox p {
    margin-top: 0px;
    text-align: center;
}

.premiumText {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #3A4248;
    word-wrap: break-word;
}

.premiumBox Button {
    align-self: center;
    text-align: center;
    color: white;
}

.premiumBox Link {
    text-decoration: none;
}

.premiumSmallText {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    color:#3A4248;
}

.searchRow {
    display: flex;
    flex-direction: row;
}

/* PROFILE PAGE **/
.profileBox {
    padding-left: 16px;
    padding-right: 12px;
    border: 0px solid #DDDDDD; 
    border-radius: 0.3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.profileBox p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #071B2E;
}

.informationBox p {
    font-weight: 500 !important;
}

.abonnementScreenBox {
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #DEDEDE;  
    margin-bottom: 24px;  
    position: relative;
}

.abonnementScreenBox .freeTrialHelperText {
    background: linear-gradient(90deg, #F46B45 0%, #EEA849 100%);
    border-radius: 4px;
    color: #FFFFFF !important;
    font-size: 12px;
    position: absolute;
    padding: 0.45rem 1rem;
    right: 16px;
    top: 16px;
}

.customer-card-detail {
    background-color: transparent;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 0.85rem;
    padding: 1rem;
    width: max-content;
}

.customer-card-detail div {
    display: flex;
    flex-direction: column;
}

.customer-card-detail .card-number {
    letter-spacing: 0.2rem;
}

.show-payment-icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
}

.subscription-end-info {
    color: #F56E46;
    display: block;
    margin-top: 1.25rem;
}

.subscription-message {
    align-items: center;
    color: #868686;
    display: flex;
    font-size: 1.5rem;
    flex-direction: column;
    margin-top: 15rem;
}

.reviveSubscription {
    display: block;
    margin-top: 0.5rem !important;
}

.reviveSubscription button{
    background-color: transparent;
    border: none;
    font-weight: 600;
    padding: 0;
    text-decoration: underline;
}

.abonnementScreenBox.subscription-termination .subscription-end-info{
    color: #071B2E;
}

.subscription-termination {
    cursor: pointer;
    background-color: rgba(255, 203, 188, 0.1);
    padding: 16px;
    border: 2px solid #868686;  
}

@media screen and (max-width: 768px) { 
    .subscription-message {
        font-size: 1.2rem; 
        margin: 10rem 0;
    }

    .customer-card-detail {
        width: 90%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .abonnementList {
        padding: 0em 5em;
    }
}

@media screen and (min-width: 1025px) {
    .abonnementList {
        display: flex;
        justify-content: space-around;
        margin-top: 2rem;
    }

    .abonnementList div {
        min-width: 35%;
    }
}


.abonnementScreenBox p {
    margin: 0px;
    text-align: start !important;
}

.abonnementTitle {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.abonnementScreenBox .abonnementTitle {
    margin-bottom: 12px;
}

.abonnementScreenBox .abonnementText {
    font-family: Inter;
    font-size: 16px;
    font-weight: 00;
    text-align: start;
}

.abonnementScreenBox .abonnementTextS {
    font-family: Inter;
    font-size: 14px;
    font-weight: 00;
    text-align: start;
    margin: 5px 0;
}

.abonnementScreenBox.selectedAboBox p {
    color: #F56E46;
}

.selectedAboBox {
    background-color: rgba(255, 203, 188, 0.1);
    padding: 16px;
    border: 2px solid #F56E46;  
}

.signoutLink .bottomBtnFixed {
    position: absolute;
    bottom: 48px;
}

.signoutLink .bottomBtnFixed Button {
    text-transform: unset !important;
    padding: 0px 12px 0px 16px;
    background-color: #E9E9E9;
    justify-content: space-between;
    width: 100%;
}

@media screen and (min-width: 1024px) {
.signoutLink .bottomBtnFixed {
    width: 80%;
}
}

.mainHome .bottomBtnQuestionnaire {
    justify-items: center;
}
  
.mainHome .bottomBtnQuestionnaire Button {
    padding: 12px;
    text-transform: unset !important;
    position: absolute;
    bottom: 16px;
    font-weight: 400;
}

.premiumProfileText {
    margin-bottom: 0px;
}

/** Popup Unsub */
.unsubPopup-content {
    padding: 1em 2em 1em 2em;
    width: 90%;
    border-radius: 1em;
    border-color: #942323;
    border-width: 3px;
}

.reviveSubscriptionPopup-content {
  padding: 1em 2em 1em 2em;
  width: 90%;
  border-radius: 1em;
  border-color: #239497;
  border-width: 3px;
}

.unsubPopupRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.unsubPopupBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

/** Popup Langage */
.languageTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.languageTitle p {
    font-family: Inter;
    font-weight: 600;
    font-size: 16px;
}

.languagePanel {
    padding: 0px 24px 8px 16px;
}

.languagePanel p {
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
}

.languageList {
    padding-left: 24px;
}

.languageList div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.languageList p {
    align-items: center;
    margin-top: 3px;
    padding-left: 16px;
}
