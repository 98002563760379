.iconPlus {
    align-self: center;
    justify-self: center;
    padding-top: 5px;
}

.returnArrow {
    justify-self: flex-start;
}

.infoBtn {
    justify-self: flex-end;
}