@media screen and (max-height: 599px) {
    .bottomBtnAuth {
        margin-top: 100px;
        position: relative;

    }
    
    .bottomBtnAuth Button {
        width: 100% !important;
    }
}

@media screen and (min-height: 600px) {
  .bottomBtnAuth {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
}

@media screen and (max-height: 699px)  {
    .bottomBtnCo {
        margin-top: 36px;
        margin-bottom: 12px;
        position: relative;
    }

    .bottomBtnCo Button {
        width: 100% !important;
    }

    .checkboxSignIn.termsAndCondition {
        align-items: flex-start;
    }

    .checkboxSignIn.termsAndCondition p {
        margin-block-start: 0.75em;
    }
}

@media screen and (max-width: 374px) {
    .bottomBtnCo {
        margin-top: 24px;
        margin-bottom: 12px;
        position: relative;
    }

    .bottomBtnCo Button {
        width: 100% !important;
    }
}

@media screen and (min-height: 700px) and (min-width: 375px) {
  .bottomBtnCo {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 1023px) {
    .authLogo {
        height: 250px;
    }

    .authDisplay {
        display: flex;
        flex-direction: column;
    }

    .authDisplayXL {
        display: none !important;
    }
}

@media screen and (min-width: 1024px) {
    .authLogo {
        height: 375px;
    }

    .authDisplay {
        margin-top: 8%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .authDisplayText {
        max-width: 40%;
        text-align: center;
        justify-content: center;
    }

    .authDisplay p {
        text-align: center;
        width: 100%;
    }

    .authDisplayM {
        display: none !important;
    }
}

.authLogo {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bottomBtnAuth {
    display: flex;
    flex-direction: column;
}

.bottomBtnCo {
    display: flex;
    flex-direction: column;
}

.mainAuth {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    background-color: #239497;
    background-size: cover;
    min-height: 100vh;
}

.headerAuth {
    text-align: center;
    margin-top: 0px;
    padding-top: 16px;
}

.headerSignIn {
    text-align: center;
    justify-content: space-between;
    margin-top: 0px;
    padding-top: 8px;
    display: flex;
    flex-direction:row;
    margin-left: 25px;
    margin-right: 18px;
    align-items: center;
}

.headerSignIn p {
    font-family: Inter;
    color: #FFF;
}

.checkboxSignIn {
    display: flex;
    flex-direction:row;
    margin-top: 1.5rem;
}

.checkboxSignIn p {
    font-size: 14px;
    max-width: 75%;
}

.checkboxSignIn a {
    color: #000000;
    font-weight: 600;
}

.checkboxSignIn.termsAndCondition {
    margin-bottom: 1rem;
}

.checkboxSignIn.proInput {
    margin-top: 0.2rem;
}

.proInput, .termsAndCondition, .acceptNewsletter {
    margin-left: -0.65rem;
}

.dataAuth {
    padding-left: 16px;
    padding-right: 16px;
}

.titleAuth {
    font-family: 'Poppins';
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    color: #FFF;
    font-size: 28px;
    font-weight: 700;
}

.textAuth {
    margin-top: 8px;
    color: #FFF;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
}

.mainSignIn {
    padding-top: 8px;
    padding-left: 1em;
    padding-right: 1em;
    background-color: #FFFFFF;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
}

.loginTxt {
    color: black;
    text-align: end;
    font-size: 12px;
    text-decoration-line: none !important;
    font-weight: 600;
}

.authTitle {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
    margin-bottom: 18px;
}

.authTitleCenter {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    margin-bottom: 12px;
}

.errorLabel {
    text-align: left;
    color: #dd0033;
    font-size: 16px;
    font-weight: 600;
}
