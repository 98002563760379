/** RESPONSIVE MARGINS */
@media screen and (max-height: 919px) {
  .fixedBtn {
    text-align: end;
    margin-left: 0px;
    margin-right: 0px;
  }
  .fixedBtn Button {
    margin-bottom: 16px;
    margin-top: 24px;
    background-color: #239497;
    color: #FFFFFF;
  }

  .bottomBtnFullWidth {
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: center;
  }
  
  .bottomBtnFullWidth Button {
    text-transform: unset !important;
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0px 12px 0px 16px;
    background-color: #E9E9E9;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (min-height: 920px) {
  @media screen and (max-width: 519px) {
    .bottomBtnFullWidth Button {
      width: 90%;
    }
  }
  @media screen and (min-width: 520px) and (max-width: 680px) {
    .fixedBtn Button {
      margin-left: 5%;
      margin-right: 5%;
    }
    .bottomBtnFullWidth Button {
      width: 86%;
    }
  }

  @media screen and (min-width: 680px) and (max-width: 920px) {
    .fixedBtn Button {
      margin-left: 10%;
      margin-right: 10%;
    }
    .bottomBtnFullWidth Button {
      width: 78%;
    }
  }
  
  @media screen and (min-width: 920px) and (max-width: 1023px) {
    .fixedBtn Button {
      margin-left: 15%;
      margin-right: 15%;
    }
    .bottomBtnFullWidth Button {
      width: 66%;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .fixedBtn Button {
      margin-left: 15%;
      margin-right: 15%;
    }
    .bottomBtnFullWidth Button {
      width: 46%;
    }
  }

  .fixedBtn {
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: center;
  }
  
  .fixedBtn Button {
    position: absolute;
    align-self: center;
    right: 16px;
    bottom: 16px;
  }

  .bottomBtnFullWidth {
    display: flex;
    flex-direction: row;
    flex:1;
    justify-content: center;
  }
  
  .bottomBtnFullWidth Button {
    text-transform: unset !important;
    padding: 0px 12px 0px 16px;
    position: absolute;
    background-color: #E9E9E9;
    bottom: 24px;
    justify-content: space-between;
  }
}

.bottomBtnFixed {
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content: center;
}

.bottomBtnFixed Button {
  position: absolute;
  align-self: center;
  bottom: 16px;
}

@media screen and (max-width: 768px) {
  .mainFooter {
    width: 100%;
  }
  .bottomBtnQuestionnaire Button {
    width: 93%;
  }
  .bottomBtnFixed {
    width: 91%;
 }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .mainHome {
    padding-left: 10%;
    padding-right: 10%;
  }
  .mainAuth {
    padding-left: 15%;
    padding-right: 15%;
  }
  .mainDeclaration {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .mainImputabilite {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .premiumApp {
    padding-left: 15%;
    padding-right: 15%;
  }
  .mainSignIn {
    padding-left: 15%;
    padding-right: 15%;
  }
  .mainInfos {
    margin-left: 10%;
    margin-right: 10%;
  }  
  .bottomBtn Button {
    margin-left: 10%;
    margin-right: 10%;
  }
  .bottomBtnQuestionnaire Button {
    width: 70%;
  }
  .bottomBtnFixed {
    width: 70%;
  }
  .mainFooter {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1024px) {
  .mainHome {
    padding-left: 10%;
    padding-right: 10%;
  }
  .mainAuth {
    padding-left: 10%;
    padding-right: 10%;
  }
  .mainDeclaration {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .mainImputabilite {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .premiumApp {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .mainSignIn {
    padding-left: 15% !important;
    padding-right: 15% !important;
  }
  .mainInfos {
    padding-top: 16px;
    margin-left: 25%;
    margin-right: 25%;
  }
  .bottomBtn Button  {
    margin-left: 15%;
    margin-right: 15%;
  }
  .mainHome .bottomBtnQuestionnaire Button {
    width: 80%;
  }
  .bottomBtnQuestionnaire Button {
    width: 70%;
  }
  .bottomBtnFixed {
    width: 70%;
  }
  .mainFooter {
    height: 0;
  }
  .infoPopup {
    width: 50%;
  }
}

@media screen and (max-width: 1300px) {
  .infoPopup {
    min-width: 80%;
  }
}

/*** MAIN USE ***/
.mainApp {
  background-color: #239497;
}

.headerApp {
  position: sticky;
  background-color: #239497;
  z-index: 2;
  color: white;
  left: 0px;
  top: 0px;
  border-radius: 0px;
}

.headerDeclaration {
  display: flex;
  flex-direction:row;
  height: 56px;
  margin-left: 25px;
  margin-right: 18px;
  align-items: center;
  justify-content: space-between;
}

.headerDeclarationLeft {
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  column-gap: 0.5em;
}

.headerDeclarationCenter {
  display: flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
}

.declarationInfos {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 0.25em;
}

.headerText {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  color: #FFF;
  letter-spacing: 0em;
  text-align: center;
}

p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  color: #071B2E;
}

.mainDeclaration {
  padding-top: 8px;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #FFFFFF;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

/** Footer */
.mainFooter {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background-color: #FEFEFE;
  z-index: 2;
  max-height: 48px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 6px;
}

.mainFooterText {
  color: #AFAFAF;
  font-size: 12px;
  padding-bottom: 10px;
  cursor: pointer;
}

.mainFooterTextBlue {
  color: #239497;
  font-size: 12px;
  padding-bottom: 10px;
  cursor: pointer;
}

.linkFooter {
  text-decoration-line: none;
}

.footerCol {
  text-align: center;
}

.endTitle {
  margin-top: 35%;
  justify-content: center;
  align-self: center;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  color:#239497;
}


/***** All screens *******/

.pageTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

/*
  Stepper
*/
.lineVectors {
  position: relative;
  margin-bottom: 48px;
  z-index: 1;
  
}

.lineVector {
  position: absolute;
  width: 25%;
  border: 2px solid #239497;
  background-color: #239497;
  border-radius: 3px;
  height: 0px;
  z-index: 2;
}

.lineVectorFull {
  position: absolute;
  width: 100%;
  border-radius: 3px;
  border: 2px solid #E9E9E9;
  background-color: #E9E9E9;
  height: 0px;
}



/* 
  Misc
*/

.separator {
  border: 1px solid #E9E9E9;
  border-radius: 3px;
  width: 100%;
}


.robotoFontB {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;

}
.robotoFont {
  color: #222E50;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

/** Buttons */

svg {
  cursor: pointer;
}

button {
  cursor: pointer;
  border: 0px;
  border-radius: 2px;
  padding: 8px 22px 8px 22px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.46px; 
  text-align: center;
}

.bottomBtnForm{
  text-align: end;
  margin-top: 24px;
  margin-bottom: 16px;
}

.nextButton {
  position: absolute;
  bottom: 16px;
  right: 16px;
  align-self: flex-end;
  background-color: #239497;
  color: #FFFFFF;
}

.waitButton {
  position: absolute;
  align-self: flex-end;
  bottom: 16px;
  right: 16px;
  background-color: #DEDEDE;
  color: #CACACA;
}

.bottomBtn {
  display: flex;
  flex-direction: row;
  flex:1;
  justify-content: center;
}

.bottomBtn Button {
  position: absolute;
  align-self: center;
  right: 16px;
  bottom: 16px;
}

.nextBottomButton {
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: #239497;
  color: #FFFFFF;
}

.waitBottomButton {
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: #DEDEDE;
  color: #CACACA;
}

.linkButton {
  letter-spacing: 1px;
  text-decoration-line: none;
  min-width: 10em;
  color: #E9E9E9;
}


/** Inputs */

.inputForm {
  row-gap: 16px;
  display: flex;
  flex-direction:column;
}

.inputForm .TextField {
  margin-bottom: 16px;
}

.inputFormUserdata {
  width: inherit;
  padding: 16px;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 16px;
}

.inputFormUserdataLast {
  width: inherit;
  padding: 16px;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 0px;
}

.inputSmallRow {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  width: 100%;
}

.inputSmall {
  width: 40%;
}

.inputDropdown {
  width: 100%;
  border: 1px solid #DEDEDE;
  padding: 16px;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 16px;
  margin-bottom: 16px;
}

.errorText {
  font-family: Roboto;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #942323;
  padding-bottom: 6px;
}

.textArea {
  width: inherit;
  padding: 10px;
  height: 6em;
  font-family: Roboto;
  font-size: 16px;
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  margin-bottom: 16px;
}

/* Forms */

.formRow {
  display: flex;
  flex-direction:row;
  padding-left: 12px;
}

.formRowDouble {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.subBoxRow {
  padding-left: 12px;
  margin-left: 43px;
}


.formRow p {
  font-family: Roboto;
  padding-left: 12px;
}

.smallForm {
  row-gap: 0px;
}

.subBoxSmall {
  display: flex;
  flex-direction:row;
  padding-left: 43px;
  max-height: 2.5em;
  align-items: center;
}




/****** SHARED COMPONENTS ********/


.infoText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  word-wrap:break-word;
  text-justify: auto;
}

.infoText a {
  color: black;
  font-weight: 600;
}

.paysDropdown {
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  color: #DEDEDE;
}

/** Screen INFOS */

.infoPanel p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}


/** Screen DECLARATION VALIDE */

.validationTitle {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-align: center;
  color: #239497;
  margin-top: 32px;
}

.validationText {
  justify-content: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
}

.boxBtn {
  margin-bottom: 24px;
  text-align: center;
}

.boxBtn a {
  text-decoration: unset;
}


.boxBtn .fullBtn {
  justify-content: space-between;
}

.validationSteps {
  font-family: 'Inter';
  font-weight: 800;
  font-size: 16px;
  color: #071B2E;
  letter-spacing: 0em;
}

.downloadBtn {
  align-self: center;
  background-color: #239497;
  color: #FFFFFF;
}

.inputRadioRow {
  display: flex;
  flex-direction:row;
  justify-content: space-around;
  width: 100%;
}

.inputRadioRowGrid {
  display: flex;
  flex-direction:row;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
}


.inputRadioRowGrid label {
  min-width: 25%;
}

.inputCheckboxRow {
  display: flex;
  flex-direction:row;
  justify-content: flex-start;
  width: 100%;
  margin-left: 0.35rem;
}

.professionForm {
  padding: 0.65rem;
}

.professionForm > .inputRadioRow label > span {
  padding: 0 9px 0 0;
}

.professionForm > .inputRadioRow > label {
  flex: 1;
}

.premiumBoxLink {
  box-sizing: border-box;
  margin-top: 2em;
  padding: 1em 2em;
  background: linear-gradient(#fff, #fff), 
              linear-gradient(111.87deg, #F46B45, #EEA849);
  border: 5px solid transparent;
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
  border-radius: 1rem;
  border-width: 10px;
  position: relative;
  text-align: center;
}

.premiumBoxLink::before {
  content: "";
  position: absolute;
  top: -5px;
  bottom: -5px;
  left: -5px;
  right: -5px;
  border: 6px solid #fff;
  border-radius: .8rem;
}

.premiumBoxLink p {
  margin-top: 0em;
  margin-bottom: 0.25em;
  padding: 0;
  text-align: center;
}

.premiumBoxLink Button {
  margin-top: 1em;
  margin-bottom: 0em;
}

.premiumTextLink {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #3A4248;
  word-wrap: break-word;
}

.premiumBoxLink Link {
  text-decoration: none;
}

/** SCREEN Fiche Produit */

.productTitle {
  margin-top: 0px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-top: 1em;
}

.productSubtitles {
  margin-top: 0px;
  margin-bottom: 0.25em;
  padding-left: 0.25em;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}

.productDetail {
  margin: 0em;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding-left: 0em;
}

.productData {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  justify-self: center;
  margin: 1em;
}


@media screen and (max-width: 1023px) {
  .productDisplay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 2em;
  }

  .productChart {
    margin-top: 0.5em;
    justify-content: center;
    text-align: center;
    min-height: 15em;
    border: 1px blue;
    border-radius: 0.5em;
  }
}

@media screen and (min-width: 1024px) {
  .productDisplay {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-bottom: 4em;

  }

  .productChart {
    margin-top: 2em;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 50%;
    min-height: 20em;
    border: 1px blue;
    border-radius: 0.5em;
  }

  .productMap {
    justify-content: center;
    max-width: 80%;
    margin-left: 12em;
    margin-right: 12em;
  }
}

.productMapLegend {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 0;
  padding-top: 0.5em;
}

.productMapLegend div {
  font-size: 12px;
  display:inline-block;
}

/** SCREEN Historique Test Imputabilité */
.bottomBtnHisto {
  display: flex;
  flex-direction: column;
}

.bottomBtnHisto div {
  padding: 12px 0px 12px 0px;
  text-transform: unset;
}

@media screen and (max-height: 800px)  {
  .bottomBtnHisto {
      margin-top: 150px;
      position: relative;
  }
  .bottomBtnHisto Button {
      width: 100% !important;
  }
}

@media screen and (max-width: 425px) {
  .bottomBtnHisto {
      margin-top: 200px;
      position: relative;
  }
  .bottomBtnHisto Button {
      width: 100% !important;
  }
}

@media screen and (min-height: 801px) and (min-width: 426px) {
  .bottomBtnHisto {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
}



@media screen and (max-height: 800px) {
  .historyTestBtn1 Button {
      margin-bottom: 128px;
  }
  
  .historyTestBtn2 Button {
    margin-bottom: 64px;
  }
  
  .historyTestBtn3 Button {
    margin-bottom: 0px;
  }
}


@media screen and (max-width: 767px) and (min-height: 801px)  {
  .historyTestBtn1 Button {
    width: 92%;
    margin-bottom: 176px;
  }
  
  .historyTestBtn2 Button {
    width: 92%;
    margin-bottom: 112px;
  }
  
  .historyTestBtn3 Button {
    width: 92%;
    margin-bottom: 48px;
  }
}





@media screen and (min-width: 768px) and (max-width: 1023px) and (min-height: 800px) {
  .historyTestBtn1 Button {
    width: 80% !important;
    margin-bottom: 176px;
  }

  .historyTestBtn2 Button {
    width: 80% !important;
    margin-bottom: 112px;
  }
  
  .historyTestBtn3 Button {
    width: 80% !important;
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1024px) and (min-height: 800px) {
  .historyTestBtn1 Button {
      width: 80% !important;
      margin-bottom: 128px;
  }
  
  .historyTestBtn2 Button {
    width: 80% !important;
    margin-bottom: 64px;
  }
  
  .historyTestBtn3 Button {
    width: 80% !important;
    margin-bottom: 0px;
  }
}

.resultatTitleHistory {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #071B2E;
  margin-bottom: 0em;
  margin-top: 0.5em;
}

/** Dataset */

.mailAdressEndForm {
  color: #165e61;
  text-decoration-line: underline !important;
  text-align: center;
  font-weight: 600;
  margin-top: 0;
}

/** Required Field */

.requiredMessage {
  color: #474545;
  font-size: 0.9rem;
  font-style: italic;
}
