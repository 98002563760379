.headerImputabilite {
  display: flex;
  flex-direction: row;
  height: 56px;
  margin-left: 25px;
  margin-right: 18px;
  align-items: center;
  justify-content: space-between;
}

.mainImputabilite {
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #FFFFFF;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.infoImputabilite {
  align-self: center;
  margin-left: 16px;
  margin-right: 16px;
}

.questionForm div {
  margin-bottom: 6px;
}

.infoProduit {
  padding: 16px;
}

.infoProduit p {
  font-size: 18px;
}

.infoProduit ul li {
  font-family: Roboto;
  padding: 0.5em;
}

.infoProduit ul {
  margin-top: 0;
}

.infoProdText {
  max-width: 60%;
}

.infoListImg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.infoList li {
  font-family: Roboto;
  padding: 0.5em;
}

.p1 {
  background-color: #2394971A;
}

.mainInfos {
  background-color: #239497;
}

.listInfos {
  background-color: #FFFFFF;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

.dataForm {
  margin-bottom: 12px !important;
}

.mainImputabilite .formRow p {
  cursor: pointer;
}

.infoQuestion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 10px 14px;
  border-radius: 0.5em;
  background-color: #0096FF33;
  padding-left: 12px;
  padding-right: 12px;
}

.infoQuestion p {
  padding-left: 12px;
  max-width: 90%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #222E50;
}

.questionnaireQuestion {
  text-align: left;
  font-family: Poppins;
  word-wrap: break-word;
  letter-spacing: 0.01em;
  color: #222E50;
}

.alertQuestion {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 16px 0 10px 16px;
  border-radius: 0.5em;
  background-color: #F0510033;
  padding-left: 12px;
  padding-right: 12px;
}

.alertQuestion p {
  padding-left: 12px;
  max-width: 90%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #9F3700;
}

.infoTextPro {
  border: 3px solid;
  border-color: #581f00;
  border-radius: 1em;
  padding: 0em 0.75em;
}

.infoTextPro p {
  font-size: 18px;
  color: #9F3700;
  font-weight: 600;
}

.dataMainBoxTitle {
  font-weight: 600;
  margin-bottom: 0;
}

.dataMainBox {
  padding: 1em;
  border: 4px solid #239497;
  border-radius: 1rem;
  position: relative;
  margin-bottom: 1.25em;
}

.dataMainBox label {
    font-weight: 600;
}

.dataMainBox.blueBox {
  box-sizing: border-box;
  background: linear-gradient(#fff, #fff), 
              linear-gradient(111.87deg, #00CDAC, #02AAB0);
  background-repeat: no-repeat;
  background-origin: padding-box, border-box;
}

.proIconHelp {
 margin-top: 1em;
}

.proIconHelpbox {
  border: 1px solid #F46B45;
  border-radius: 0.5em 0.5em 0.5em 0em;
  padding: 1em;
  max-width: 50%;
  margin-left: 4em;
  margin-bottom: -2em;
}



/** */

.bottomLeft Button {
  position: absolute;
  align-self: center;
  left: 16px;
  bottom: 16px;
  right: inherit !important;
}

.mainImputabilite .bottomBtnQuestionnaire {
  justify-items: center;
}

.mainImputabilite .bottomBtnQuestionnaire Button {
  position: absolute;
  bottom: 16px;
}

.premiumResultatBox {
  background: linear-gradient(111.87deg, #F46B45, #EEA849);
  border-radius: 1em;
  position: relative;
}

.premiumResultatBox p {
  text-align: center;
}

.premiumResultatText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0em;
  color: #FDF6EC;
  padding-top: 6px;
  margin-top: 0px;
  margin-bottom: 4px;
}

.premiumResultatBox Link {
  text-decoration: none;
}

.premiumResultatSmallText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  word-wrap: break-word;
  margin-top: 0px;
  padding: 4px 8px 8px 8px;
}


/* Temporary : ToDo Publicité */

.pubPage p {
  text-align: center;
}

.pubPage button {
  margin-top: 2em;
}

.pubPageLabel {
  text-align: center;
  text-decoration: unset !important;
  color: #F46B45;
  font-size: 18px;
  font-weight: 600;
}

/** Resultat */
.alertProPanel {
  padding: 1em 2em;
}

.alertProPanel p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
}

.alertProTitle {
  text-align: center !important;
}

.alertProTitle p {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.resultatLien {
  display: flex;
  padding: 6px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.5em;
}

.resultatHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.resultatScore {
  font-size: 16px;
  font-weight: 600;
  margin-right: 2em;
  align-self: baseline;
}

.resultatTitle {
  padding-top: 0.5em;
  padding-left: 2em;
  display: flex;
  align-items: baseline;
  column-gap: 1em;
}

.resultatTitle p {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #071B2E;
}

.resultRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1em;
  padding: 0em 1em;
}

.premiumLinks {
  font-weight: 600;
  text-decoration: unset !important;
  text-decoration-color: unset !important;
  color: #F46B45 !important;
}

.thanksBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thanksBox .acceptBtn {
  bottom: 16px;
  display: flex;
  justify-content: center;
  position: absolute;
  width: 90%;
} 

.thanksTitle {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.checkboxAccept {
  display: flex;
  flex-direction: row;
}

.checkboxAccept p {
  font-size: 12px;
  max-width: 75%;
  text-align: start;
}

.checkboxAccept a {
  color: #000000;
  font-weight: 600;
}

@media screen and (max-height: 700px) and (max-width: 375px) {
  .acceptBtn {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
}

@media screen and (max-height: 699px) {
  .acceptBtn {
    margin-top: 100px;
    margin-bottom: 12px;
    position: relative !important;
  }

  .acceptBtn Button {
    width: 100% !important;
  }
}

@media screen and (max-width: 374px) {
  .acceptBtn {
    margin-top: 100px;
    margin-bottom: 12px;
    position: relative !important;
  }

  .acceptBtn Button {
    width: 100% !important;
  }
}

.bottomBtnDecla {
  display: flex;
  flex-direction: column;
}



@media screen and (max-height: 675px) {
  .bottomBtnDecla {
    margin-top: 270px;
    position: relative;
  }

  .resultInfoRow {
    font-size: 8px;
  }

  .bottomBtnDecla Button {
    width: 100% !important;
  }
}

@media screen and (max-width: 425px) {
  .bottomBtnDecla {
    margin-top: 270px;
    position: relative;
  }

  .bottomBtnDecla Button {
    width: 100% !important;
  }

  .resultInfoRow {
    font-size: 8px;
  }
}

@media screen and (min-height: 676px) and (min-width: 426px) {
  .bottomBtnDecla {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }

  .resultInfoRow {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .questionnaireQuestion {
    font-size: 18px;
    font-weight: 700;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 769px) and (max-width: 1023px) {
  .questionnaireQuestion {
    font-size: 18px;
    font-weight: 700;
  }
}

@media screen and (min-width: 1024px) {
  .questionnaireQuestion {
    font-size: 18px;
    font-weight: 700;
  }
}

.mainImputabilite > .questionnaireSubmitButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.mainImputabilite .stepper {
  margin-top: 0.5rem;
}

.adverseEffectForm {
  counter-reset: css-counter 0; 
}

.adverseEffectForm .question {
  counter-increment: css-counter 1;
  margin-bottom: 2.5rem;
  padding: 0 0.65rem;
}

.question h2::before {
  content: counter(css-counter) ".";
}

.question h2 {
  display: flex;
  gap: 0.25rem;
}

@media screen and (max-width: 768px) { 
  .infoListImg {
    flex-wrap: wrap;
    justify-content: center;
  }
}
